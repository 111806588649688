<template>
  <div class="text-center p-3">
    <iframe
      class="w-8 h-screen max-h-30rem"
      src="https://www.youtube.com/embed/m_FKCOg8p3o?si=HA96-3eq3uQNhYdq&hd=1"
      title="Maskinrummet Intro - YouTube"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe>
    <p>{{ $t("videoOnHowTo") }}</p>
  </div>
</template>
